import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { MyOrders } from 'src/app/models/my-orders/my-orders';
import { Order } from 'src/app/models/order/order';
import { ConstantsService } from 'src/app/services/constants/constants.service';
import { GeneralService } from 'src/app/services/general/general.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService extends GeneralService<any>{
  private myOrders: any;
  private ordersInProvider = new BehaviorSubject({});
  public orders = this.ordersInProvider.asObservable();

  constructor(
    protected http: HttpClient,
  ) {
    super(http, '');
  }

  updateOrder(newOrder, orderIndex) {
    const orders = this.ordersInProvider.getValue();

    orders[orderIndex] = newOrder;
    this.ordersInProvider.next(orders);
  }

  deleteOrder(deleteOrderIndex) {
    const orders = this.ordersInProvider.getValue();

    delete orders[deleteOrderIndex];
    this.ordersInProvider.next(orders);
  }

  getMyOrderById(orderId: number): Observable<any> {
    let myOrder;
    if (this.myOrders) {
      myOrder = this.myOrders.data.pending.find((myOrder) => myOrder.id === orderId);
      if (!myOrder) {
        myOrder = this.myOrders.data.confirmed.find((myOrder) => myOrder.id === orderId);
      }
      return myOrder;

    } else {
      const data = this.ordersByGuestId();
      return new Observable(observer => {
        data.subscribe(response => {
          myOrder = response.data.pending.find((myOrder) => myOrder.id === orderId);
          if (!myOrder) {
            myOrder = response.data.confirmed.find((myOrder) => myOrder.id === orderId);
          }

          observer.next(myOrder);
          observer.complete();
        });
      });
    }
  }

  getRepeatOrder(id: number, provider_type_name: string): Observable<ApiResponse<any>> {
    const getData = {
      provider_type_name
    };

    return this.http
      .get<ApiResponse<any>>(`${this.getPrivateApiPath()}/orders/${id}/repeat-order`, {params: getData});
  }

  sendOrder(provider: string, order: Order): Observable<any> {
    const postData = {
      order,
      payment_type_id: order.paymentTypeId,
      provider_type: this.parseFrontendToBackendIndex(provider),
      provider_id: order.provider.id
    };
    return this.http
      .post<any>(`${this.getPrivateApiPath()}/create-order`, postData, { headers: this.headers });
  }

  ordersByGuestId(): Observable<any> {
    const vm = this;

    return this.http
      .get<any>(`${this.getPrivateApiPath()}/orders-by-guest`)
      .pipe(
        shareReplay(),
        map((data: ApiResponse<MyOrders>) => {
          return vm.buildOneResponse(data);
        })
      );
  }

  parseBackendToFrontendIndex(type: string): string {
    switch (type) {
      case ConstantsService.BACKEND_RESTAURANTS_INDEX:
        return ConstantsService.RESTAURANTS_INDEX;
      case ConstantsService.BACKEND_ROOMSERVICE_INDEX:
        return ConstantsService.ROOMSERVICE_INDEX;
      case ConstantsService.BACKEND_AMENITIES_INDEX:
        return ConstantsService.AMENITIES_INDEX;
    }
  }

  parseFrontendToBackendIndex(type: string): string {
    let parseToBackend = type;
    switch (type) {
      case ConstantsService.RESTAURANTS_INDEX:
        parseToBackend = ConstantsService.BACKEND_RESTAURANTS_INDEX;
        break;
      case ConstantsService.ROOMSERVICE_INDEX:
        parseToBackend = ConstantsService.BACKEND_ROOMSERVICE_INDEX;
        break;
      case ConstantsService.AMENITIES_INDEX:
        parseToBackend = ConstantsService.BACKEND_AMENITIES_INDEX;
        break;
    }

    return parseToBackend;
  }

  protected parseDataToObject(data) {
    return MyOrders.instanceFromJson(data);
  }
}
