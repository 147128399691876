import { Translations } from '../translations/translations'

export class OrderState {
  public id: number;
  public created_at: string;
  public updated_at: string;
  public translations: Translations;

  static instanceFromJson(data: any): OrderState {
    let instance = new OrderState();
    instance.id = data.id;
    instance.created_at = data.created_at;
    instance.updated_at = data.updated_at;
    instance.translations = Translations.instanceFromJson(data.translations);

    return instance;
	}
}