import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { ConstantsService } from '../services';
import { StorageService } from '../services/storage/storage.service';

@Injectable()
export class ErrorHandler implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 0 || err.status === 429) {
          this.router.navigate(['/errors/something-went-wrong']);
        } else if (err.status === 403) {
          this.router.navigateByUrl('/landing/still-pending');
        } else if (err.status !== 400 && err.status !== 409 && err.status !== 500) {
          this.router.navigate(['/errors/return-to-home']);
        } else if (err.error.data?.message === ConstantsService.PROPERTY_NOT_FOUND_INDEX) {
          this.router.navigate(['/errors/property-not-found']);
        }
        return throwError(err);
      })
    );
  }
}