import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  // LOCALSTORAGE
  public static LOCALSTORAGE_TOKEN_INDEX = 'token';
  public static LOCALSTORAGE_LANGUAGE_INDEX = 'language';
  public static LOCALSTORAGE_LOGO_IMAGE_URL_INDEX = 'logo_image_url';
  public static SHOW_SPLASH_IMAGE = true;

  // LANGUAGES
  public static LANGUAGE_ES_INDEX = 'es';
  public static LANGUAGE_EN_INDEX = 'en';
  public static LANGUAGE_PT_INDEX = 'pt';
  public static LANGUAGE_FR_INDEX = 'fr';

  // PROVIDERS INDEX
  public static RESTAURANTS_INDEX = 'restaurants';
  public static ROOMSERVICE_INDEX = 'room-service';
  public static AMENITIES_INDEX = 'amenities';

  // BACKEND PROVIDERS INDEX
  public static BACKEND_RESTAURANTS_INDEX = 'restaurants';
  public static BACKEND_ROOMSERVICE_INDEX = 'room_service';
  public static BACKEND_AMENITIES_INDEX = 'amenity';

  // STRING FROM BACKEND
  public static PROPERTY_NOT_FOUND_INDEX = 'Property Acronym not found';

  // PROVIDER ALLOWS ORDERS
  public static ROOM_SERVICE_ORDERS = true;
  public static RESTAURANTS_ORDERS = true;
  public static AMENITIES_ORDERS = true;

  // LOYALTY PROGRAM
  public static ACCOUNT_NUMBER_INDEX = 'loyalty_programs.account_number';

  // ERROR CODES
  public static USER_ERROR_CODE: string = '400';
  public static SERVER_ERROR_CODE: string = '500';

  // VISIBILITY OPTIONS
  public static EVERYWHERE_VISIBLE_OPTION = 'everywhere';

  constructor() { }
}
