import { OrderProduct } from '../order-product/order-product';
import { OrderState } from '../order-state/order-state';
import { ProviderType } from '../provider-type/provider-type';
import { Provider } from '../provider/provider';

export class Order {
	//Datos desde backend
	public id: number;
	public notes: string;
	public property_id: number;
	public order_state_id: number;
	public is_canceled: boolean;
	public bill_id: number;
	public provider_type: ProviderType;
	public order_state: OrderState;
	public updated_at: string;
	public created_at: string;

	//Ambos
	public provider: Provider;
	public products: OrderProduct[];
	public delivery_date: string;

	//Para manejo de frontned
	public next_index: number;
	public currency: string;
	public total: number;
	public amount: number;
	public providerName: string;
	public paymentTypeId: number;
	public deliveryDate: Date;

	static instanceFromJson(data: any): Order {
		let instance = new Order();
		instance.id = data.id;
		instance.notes = data.notes;
		instance.property_id = data.property_id;
		instance.order_state_id = data.order_state_id;
		instance.is_canceled = data.is_canceled;
		instance.bill_id = data.bill_id;
		instance.provider_type = ProviderType.instanceFromJson(data.provider_type);
		instance.order_state = OrderState.instanceFromJson(data.order_state);
		instance.updated_at = data.updated_at;
		instance.created_at = data.created_at;
		instance.provider = Provider.instanceFromJson(data.provider);

		instance.setProducts(data.products);
		instance.delivery_date = data.delivery_date;
		instance.currency = data.currency;
		instance.providerName = data.provider.name;
		
		return instance;
  }

	constructor() {
		this.clear();
	}

	public clear() {
		this.provider_type = new ProviderType();
		this.provider = new Provider();
		this.currency = '';
		this.delivery_date = null;
		this.products = [];
		this.next_index = 1;
		this.total = 0;
		this.amount = 0;
	}

	public isEmpty() {
		return this.products.length == 0;
	}
	
	setProducts(products: OrderProduct[]) {
		this.next_index = 1;
		let vm = this;
		this.products = products.map(product => {
			let orderProduct = OrderProduct.instanceFromJson(product);
			orderProduct.index = vm.next_index;
			vm.next_index++
			return orderProduct;
		});
		
		this.total = this.products.reduce((a, b) => a + (b['total'] || 0), 0);
		this.amount = this.products.reduce((a, b) => a + (b['amount'] || 0), 0);
  }

	public addProduct(newProduct: OrderProduct) {
		const productHasAdded = this.products.some(this.changeOnList(newProduct));

		if (!productHasAdded) {
			newProduct.index = this.next_index;
			this.products.push(newProduct);
			this.next_index++;
		}

		this.addValues(newProduct);
	}

	public removeProduct(productIndex: number) {
		this.sustractValues(this.products.find(product => product.index == productIndex));
		this.products = this.products.filter(product => {
			if(product.index != productIndex){
				if(product.index > productIndex){
					product.index--;
				}
				return product;
			}
		})

		this.next_index--;
	}

	public updateValues(orderProduct: OrderProduct, values) {
		this.total += (orderProduct.total - values.total);
		this.amount += (orderProduct.amount - values.amount);
	}

	public addValues(orderProduct: OrderProduct) {
		this.total += orderProduct.total;
		this.amount += orderProduct.amount;
	}

	protected sustractValues(orderProduct: OrderProduct) {
		this.total -= orderProduct.total;
		this.amount -= orderProduct.amount;
	}

	protected getProductAndIndex(orderProductIndex: number) {
		let curret_product;

		for (const [i, orderProduct] of this.products.entries()) {
			if (orderProduct.index == orderProductIndex) {
				curret_product = { product: orderProduct, index: i }
			}
		}

		return curret_product;
	}

	protected changeOnList(newProduct: OrderProduct) {
		let changed = false;
		return function (element) {
			let iterateChange = false;
			if (
				element.product_id == newProduct.product_id &&
				element.size == newProduct.size
			) {
				element.amount += newProduct.amount;
				element.total += newProduct.total;
				iterateChange = true;
			}

			changed = changed || iterateChange;
			return changed;
		}
	}
}