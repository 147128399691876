import { Order } from 'src/app/models/order/order';

export class MyOrders {
  public pending: Order[];
  public confirmed: Order[];

  static instanceFromJson(data: any): MyOrders{
    let instance = new MyOrders();
    instance.pending = data.pending.map(order => Order.instanceFromJson(order));
    instance.confirmed = data.confirmed.map(order => Order.instanceFromJson(order));

    return instance;
  }
}
