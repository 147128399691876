import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
  ) { }

  public areYousure(titleT, bodyT, anyFunction, bdDismiss = true, onlyButtonT = '') {
    let alertWords = [titleT, bodyT,'GENERAL.YES', 'GENERAL.NO', onlyButtonT];
    this.translate.get(alertWords).subscribe(traslations => {
      async function presentAlert(vm){
        const alert = await vm.alertController.create({
          header: traslations[titleT],
          message: traslations[bodyT],
          cssClass: 'contactless-texts',
          animated: true,
          backdropDismiss: bdDismiss,
          buttons: onlyButtonT !== '' ? [
            {
              text: traslations[onlyButtonT],
              handler: () => {
                anyFunction()
              }
            }
          ] : 
          [
            {
              text: traslations['GENERAL.NO'],
              role: 'cancel',
              cssClass: 'contactless',
              handler: (blah) => {}
            }, {
              text: traslations['GENERAL.YES'],
              handler: () => {
                anyFunction()
              }
            }
          ]
        });

        await alert.present();
      }
      presentAlert(this);
    });
  }
}
